import "./styles.scss"

import React from "react"
import { Fade } from "react-awesome-reveal"

import ApartmentTile from "components/ApartmentTile"

import { useFavoriteApartments } from "context/favorites"
import { useLocale } from "context/locale"

const Content = () => {
  const { t } = useLocale()
  const { favorite_apartments } = useFavoriteApartments()

  return (
    <section className="favorites-content">
      <div className="container-fluid">
        <div className="row">
          {favorite_apartments.length > 0 ? (
            favorite_apartments.map((item, index) => (
              <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                <Fade cascade triggerOnce>
                  <ApartmentTile item={item} />
                </Fade>
              </div>
            ))
          ) : (
            <p>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("Brak <strong>mieszkań</strong> w ulubionych."),
                }}
              />
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
